import React from "react"
import { Link } from "gatsby"
import Logo from "./../../../../../data/img/logo.svg"
import SvgFeature from "../../../../common/SvgFeature"

const RequestLeftCol = props => {
  const goBack = () => {
    window.history.back()
  }

  const svg = {
    ssl: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M9 12l2 2l4 -4" />
        <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
      </svg>
    ),
    list: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <path d="M3.5 5.5l1.5 1.5l2.5 -2.5"></path>
        <path d="M3.5 11.5l1.5 1.5l2.5 -2.5"></path>
        <path d="M3.5 17.5l1.5 1.5l2.5 -2.5"></path>
        <line x1="11" y1="6" x2="20" y2="6"></line>
        <line x1="11" y1="12" x2="20" y2="12"></line>
        <line x1="11" y1="18" x2="20" y2="18"></line>
      </svg>
    ),
    fingerprint: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <path d="M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3"></path>
        <path d="M8 11a4 4 0 0 1 8 0v1a10 10 0 0 0 2 6"></path>
        <path d="M12 11v2a14 14 0 0 0 2.5 8"></path>
        <path d="M8 15a18 18 0 0 0 1.8 6"></path>
        <path d="M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 12 -6.95"></path>
      </svg>
    ),
    de: (
      <svg
        width="24"
        height="24"
        className="inline"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
      >
        <defs>
          <circle id="a" cx="12" cy="12" r="12" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#D8D8D8" xlinkHref="#a" />
          <path fill="#000" mask="url(#b)" d="M-2 0h26v8H-2z" />
          <path fill="#F72032" mask="url(#b)" d="M-2 8h26v8H-2z" />
          <path fill="#F5B543" mask="url(#b)" d="M-1 16h26v8H-1z" />
        </g>
      </svg>
    ),
  }

  return (
    <div className="lg:flex flex-col">
      <button onClick={goBack} className="inline-flex w-full mb-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-3"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#1939ec"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <circle cx="12" cy="12" r="9" />
          <line x1="8" y1="12" x2="16" y2="12" />
          <line x1="8" y1="12" x2="12" y2="16" />
          <line x1="8" y1="12" x2="12" y2="8" />
        </svg>{" "}
        Zurück zur letzten Seite
      </button>
      <div className="inline-flex">
        <Link to="/">
          <img src={Logo} alt="Dierda Logo" className="mb-8 h-7" />
        </Link>
      </div>

      <div className="text-left mb-5 w-full">
        <SvgFeature
          svg={svg.ssl}
          title="Verschlüsselte Übertragung"
          text="Deine Daten werden per SSL-Verschlüsselung an uns übertragen."
        />
      </div>
      <div className="text-left mb-5 w-full">
        <SvgFeature
          svg={svg.list}
          title="Respekt vor Daten"
          text="Wir erheben nur Daten, die wir als grobe Richtlinie für Deine Anfrage benötigen."
        />
      </div>
      <div className="text-left mb-5 w-full">
        <SvgFeature
          svg={svg.fingerprint}
          title="Keine Weitergabe"
          text="Deine Daten werden nur verwendet um Deine Anfrage zu bearbeiten. Wir geben die Daten nicht weiter oder verwenden diese anderweitig."
        />
      </div>
      <div className="text-left mb-5 w-full">
        <SvgFeature
          svg={svg.de}
          title="Deutsches Rechenzentrum"
          text="Deine Daten werden in Nürnberg (Deutschland) verarbeitet."
        />
      </div>
    </div>
  )
}

export default RequestLeftCol
